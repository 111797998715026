(function ($) {
    'use strict';

    $.datePicker = new Object({
        init: function () {
            if (this.isDateSupported() === false) {
                document.write(
                    '<link rel="stylesheet" href="//ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/base/jquery-ui.css">',
                );
                document.write(
                    '<script src="//ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>',
                );

                $(document).ready(function () {
                    $.datepicker.regional.de = {
                        closeText: 'Schließen',
                        prevText: '&#x3C;Zurück',
                        nextText: 'Vor&#x3E;',
                        currentText: 'Heute',
                        monthNames: [
                            'Januar',
                            'Februar',
                            'März',
                            'April',
                            'Mai',
                            'Juni',
                            'Juli',
                            'August',
                            'September',
                            'Oktober',
                            'November',
                            'Dezember',
                        ],
                        monthNamesShort: [
                            'Jan',
                            'Feb',
                            'Mär',
                            'Apr',
                            'Mai',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Okt',
                            'Nov',
                            'Dez',
                        ],
                        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        weekHeader: 'KW',
                        dateFormat: 'dd.mm.yy',
                        firstDay: 1,
                        isRTL: false,
                        showMonthAfterYear: false,
                        yearSuffix: '',
                    };

                    $.datepicker.setDefaults({
                        changeMonth: true,
                        changeYear: true,
                        dateFormat: 'dd.mm.yy',
                        // yearRange: "1960:c-10"
                    });

                    $('input[type="date"]').addClass('legacy').datepicker($.datepicker.regional['de']);
                });
            }
        },

        isDateSupported: function () {
            var _value = 'a',
                _elem = $('<input type="date" />').val(_value);

            return _elem.val() !== _value;
        },
    });
})(jQuery);
