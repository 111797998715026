(function ($) {
    'use strict';

    $.anchorShrink = new Object({
        init: function () {
            var _maxWidth = $('.container').width(),
                _neededWidth = $('.navigation-anchor ul').width() + $('.navigation-anchor ul + ul').width();

            if (_neededWidth > _maxWidth) {
                $('.sections').addClass('shrink');
            } else {
                $('.sections').removeClass('shrink');
            }
        },
    });
})(jQuery);
