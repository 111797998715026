(function ($) {
    'use strict';

    $.formValidation = new Object({
        IBANField: $('#field-iban'),

        init: function () {
            var that = this,
                _checkIBAN = false,
                _checkForm = false;

            $(document).on('submit', 'form[novalidate]', function (e) {
                _checkIBAN = that.checkIBAN();
                _checkForm = $(this)[0].checkValidity();

                if (_checkIBAN === false || _checkForm === false) {
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).addClass('was-validated');
                    $('.remove-me').remove();

                    var _msg = $('<ul />').addClass('mb-0');

                    if (_checkIBAN === false) {
                        _msg.prepend('<li>Die IBAN ist nicht korrekt.</li>');
                    }

                    if (_checkForm === false) {
                        _msg.append('<li>Einige Felder wurden nicht ausgefüllt. Bitte prüfen.</li>');
                    }

                    $(this).prepend($('<div />').addClass('alert alert-warning remove-me').prepend(_msg));

                    $('html, body')
                        .stop()
                        .animate(
                            {
                                scrollTop: 0,
                            },
                            500,
                            'swing',
                            function () {},
                        );
                }
            });
        },

        checkIBAN: function () {
            if (this.IBANField.length > 0 && this.IBANField.is(':visible')) {
                var _checkValue = this.IBANField.val().substr(4, 5);

                if (this.IBANField.data('iban').includes(_checkValue) === false) {
                    this.IBANField.addClass('invalid');
                    return false;
                } else {
                    this.IBANField.removeClass('invalid');
                }
            }

            return true;
        },
    });
})(jQuery);
