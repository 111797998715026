(function ($) {
    'use strict';

    $.random = new Object({
        init: function () {
            $('[data-random]').each(function () {
                if ($(this).children().length > $(this).data('random')) {
                    var _arr = [];
                    $(this).children().hide();

                    while (_arr.length < $(this).data('random')) {
                        var _show = Math.floor(Math.random() * $(this).children().length) + 1;

                        if (_arr.indexOf(_show) > -1) {
                            continue;
                        }

                        $(this)
                            .find('li:nth-child(' + _show + ')')
                            .show();
                        _arr[_arr.length] = _show;
                    }
                }
            });
        },
    });
})(jQuery);
