(function ($) {
    'use strict';

    $.paymentSelect = new Object({
        init: function () {
            $('select[id*="payment"]').on('change', function () {
                var _option = $(this).find('option:selected'),
                    _replaceElem = $(this).parent().parent().find('small'),
                    _text = [
                        $(_option).data('text'),
                        $(_option).data('rates') > 1
                            ? '(<i>' +
                              $(_option).data('rates') +
                              ' Raten à ' +
                              $(_option).data('fee') +
                              ' = ' +
                              $(_option).data('feeComplete') +
                              '</i>)'
                            : '',
                    ];

                _replaceElem.html(_text.join(' '));
            });
        },
    });
})(jQuery);
