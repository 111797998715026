/**
 * examples:
 *

 $('.js-loadVideo').gdpr({
        url: '//www.youtube-nocookie.com/embed/',
        element: '.embed-responsive',
        data: 'youtube',
        params: '?rel=0&amp;showinfo=0&amp;color=red&amp;autoplay=1',
        remove: '.embed-responsive-item',
        width: 640,
        height: 385,
    });

 $('.js-loadMap').gdpr({
        url: '//www.google.com/maps/embed/v1/place',
        element: '.embed-responsive',
        data: 'map',
        params: '&key=AIzaSyCFSA2BGI7EFNeTJQuLBCfn5sdn_Wxr_9s',
        remove: '.embed-responsive-item',
        width: 640,
        height: 385,
    });

 *
 *
 */

(function ($) {
    'use strict';

    $.fn.gdpr = function (opts) {
        $(this).on('click', function (e) {
            e.preventDefault();

            var _url = opts.url,
                _elem = $(this).closest($(opts.element)),
                _data = _elem.data(opts.data),
                _uri = _url + _data + opts.params,
                _iframe = $('<iframe />')
                    .addClass('embed-responsive-item')
                    .attr('width', opts.width)
                    .attr('height', opts.height)
                    .attr('src', _uri)
                    .attr('frameborder', 0)
                    .attr('allowfullscreen', 'allowFullScreen');

            $(this).closest($(opts.remove)).remove();
            _elem.prepend(_iframe);
        });
    };
})(jQuery);
