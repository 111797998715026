(function ($) {
    'use strict';

    $.lazy = new Object({
        images: document.querySelectorAll('img[loading="lazy"]'),
        bgImages: document.querySelectorAll('[data-lazyload]'),

        init: function () {
            const body = document.getElementById('top');

            const observer = new IntersectionObserver((entries, o) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.removeAttribute('loading');

                        if (body.classList.contains('develop')) {
                            console.log({
                                message: 'removed loading attribute',
                                element: entry.target,
                            });
                        }
                    }
                });

                setTimeout(() => {
                    o.disconnect();
                }, 200);
            });

            const background = new IntersectionObserver((entries, o) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('map--bg');

                        if (body.classList.contains('develop')) {
                            console.log({
                                message: 'added bg image',
                                element: entry.target,
                            });
                        }
                    }
                });
            });

            this.images.forEach((image) => {
                observer.observe(image);
            });

            this.bgImages.forEach((bg) => {
                background.observe(bg);
            });
        },
    });
})(jQuery);
