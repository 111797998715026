(function ($) {
    'use strict';

    $.rowClick = new Object({
        init: function () {
            var _elements = $('.courses [data-reactive]:not(.not-yet-bookable)');

            _elements.on('click', function (e) {
                if (typeof $(e.target).parent().parent().data('toggle') === 'undefined') {
                    $(this).find('input[type="checkbox"]').click();
                }
            });
        },
    });
})(jQuery);
