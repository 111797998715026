(function ($) {
    'use strict';

    /**
     * adds &shy; to after @ sign in email addresses, if its not fitting
     * in the viewbox
     *
     * @type {Object}
     */
    $.shy = new Object({
        init: function () {
            $('.team-teaser a:contains("@")').each(function () {
                var _elem = $(this).find('> *:visible').first(),
                    _text = _elem.text();

                if (typeof $(this).find('> *:visible').offset() !== 'undefined') {
                    var _maxLeftPos = $(this).offset().left + $(this).width(),
                        _wantedLeftPos =
                            $(this).find('> *:visible').offset().left + $(this).find('> *:visible').width();

                    // if (_wantedLeftPos > _maxLeftPos) {
                    _elem.html(_text.replace('@', '<br>@'));
                    _elem.parent().addClass('shy');
                    // }
                }
            });
        },
    });
})(jQuery);
