(function ($) {
    'use strict';

    $.overlay = new Object({
        overlay: $('.tmp-overlay'),

        init: function () {
            if (this.overlay.length > 0) {
                if (!this.getStorage('overlay')) {
                    this.overlay.removeClass('d-none');
                }
                var that = this;
                this.overlay.find('.btn').on('click', function (e) {
                    e.preventDefault();
                    that.setStorage(1, 'overlay');
                    that.overlay.addClass('d-none');
                });
            }
        },

        setStorage: function (data, key) {
            try {
                if (typeof Storage !== 'undefined') {
                    localStorage.setItem(key, JSON.stringify(data));
                } else {
                    // no local storage supported
                }
            } catch (e) {
                // unable to write local storage
                return void 0;
            }
        },

        getStorage: function (key) {
            try {
                if (typeof Storage !== 'undefined') {
                    if (localStorage.getItem(key) !== null) {
                        return JSON.parse(localStorage.getItem(key));
                    } else {
                        return null;
                    }
                } else {
                    // no local storage supported
                }
            } catch (e) {
                // unable to read local storage
                return null;
            }
        },
    });
})(jQuery);
