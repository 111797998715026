(function ($) {
    'use strict';

    $.breadcrumb = new Object({
        init: function () {
            var _copy = $('.move-to-breadcrumb').clone();

            $('.navigation-breadcrumb__list > li:nth-child(3)')
                .addClass('submenu')
                .append(_copy.removeClass('d-none'))
                .addClass('hover');

            setTimeout(function () {
                $('.navigation-breadcrumb__list > li:nth-child(3) ul').css('opacity', 0);

                setTimeout(function () {
                    $('.navigation-breadcrumb__list > li:nth-child(3)').removeClass('hover');
                    $('.navigation-breadcrumb__list > li:nth-child(3) ul').removeAttr('style');
                }, 0);
            }, 0);
        },
    });
})(jQuery);
