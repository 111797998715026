(function ($) {
    'use strict';

    $.bookNowButtons = new Object({
        init: function () {
            var $bookNow = $('<div />').addClass(
                    'book-now alert alert-warning alert-dismissible fade show d-flex justify-content-between d-lg-none',
                ),
                $wrap = $('<div />').addClass('d-md-flex'),
                i = 0;

            $.each($('.tmpl-Courseprogram button[data-scrollto]'), function () {
                ++i;

                var _btn = $(this).clone();

                if (typeof _btn.data('mobileText') !== 'undefined' && _btn.data('mobileText') !== '') {
                    _btn.text(_btn.data('mobileText'));
                }

                $wrap.append($('<div />').append(_btn).addClass('mb-15 mr-md-30'));
            });

            if (i > 0) {
                $bookNow
                    .append($wrap)
                    .append($('<a data-dismiss="alert" />').addClass('icon icon-cross').attr('title', 'Schließen'));

                $('#header').append($bookNow);

                $(document).on('click', '.book-now button', function () {
                    $('.book-now').removeClass('show').addClass('d-none');
                });
            }
        },
    });
})(jQuery);
