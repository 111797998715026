(function ($) {
    'use strict';

    $.fn.nearby = function () {
        var _elem = $(this);
        $.each(_elem, function () {
            $.nearby.init($(this));
        });
    };

    $.nearby = new Object({
        init: function (elem) {
            var that = this,
                $element = elem;

            $(document).on('mousemove', function (e) {
                $.each($element, function () {
                    var _find = $(this).data('reactive');

                    if (that.isNear($(this), _find.distance, e)) {
                        $('.reactive').removeClass('reactive');
                        $(this).find(_find.element).addClass('reactive');
                    } else {
                        $(this).find(_find.element).removeClass('reactive');
                    }
                });
            });
        },

        isNear: function ($element, distance, event) {
            var left = $element.offset().left - distance,
                top = $element.offset().top - distance,
                right = left + $element.width() + 2 * distance,
                bottom = top + $element.height() + 2 * distance,
                x = event.pageX,
                y = event.pageY;

            return x > left && x < right && y > top && y < bottom;
        },
    });
})(jQuery);
