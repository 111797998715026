(function ($) {
    'use strict';

    $.form = new Object({
        init: function () {
            $('.toggleNextField').each(function () {
                var _listenTo = $(this).find('input[type="checkbox"]'),
                    _required = $(this).data('required');

                if ($(_listenTo).is(':checked')) {
                    $(this).next().removeClass('d-none');

                    if (parseInt(_required) === 1) {
                        $(this).next().find('input, checkbox, textarea').attr('required', 1);
                        $(this).next().find('label').addClass('required');
                    } else {
                        $.each(_required, function (field, req) {
                            var _elem = $('[name*="' + field + '"]');
                            _elem.attr('required', 1).parent().find('label').addClass('required');
                        });
                    }
                }

                var that = $(this);

                $(_listenTo).on('click', function () {
                    if ($(this).is(':checked')) {
                        that.next().removeClass('d-none');

                        if (_required === 1) {
                            that.next().find('input, checkbox, textarea').attr('required', 1);
                            that.next().find('label').addClass('required');
                        } else {
                            $.each(_required, function (field, req) {
                                var _elem = $('[name*="' + field + '"]');

                                _elem.attr('required', 1).parent().find('label').addClass('required');
                            });
                        }
                    } else {
                        that.next().addClass('d-none');
                        that.next().find('input, checkbox, textarea').removeAttr('required');
                        that.next().find('label').removeClass('required');
                    }
                });
            });
        },
    });
})(jQuery);
