(function ($) {
    'use strict';

    $.mobileNav = new Object({
        selector: $('header#header .navigation-top__main > ul'),

        init: function () {
            var that = this;

            $('.hamburger').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('is-active');
                that.selector.slicknav('toggle');
            });

            this.initSlicknav();
        },

        initSlicknav: function () {
            var that = this;
            this.selector.slicknav({
                prependTo: 'header#header .navigation-top',
                allowParentLinks: true,
                parentTag: 'span',
                wrapperTag: 'nav',
                init: function () {
                    that.enrichSlicknav();
                },
            });
        },

        enrichSlicknav: function () {
            var _cartAndLocation = $('.navigation-top__location ul').clone();

            $('.slicknav_menu').prepend($('<div />').addClass('meta').append(_cartAndLocation));
        },
    });
})(jQuery);
