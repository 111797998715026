(function ($) {
    'use strict';

    $.dataLayer = new Object({
        init: function () {
            if (typeof dataLayer !== 'undefined') {
                if ($('form[name="orderDemand"]').length > 0) {
                    $(document).on('click', 'form[name="orderDemand"] input[type="submit"].js-dataLayer', function (e) {
                        e.preventDefault();

                        $('.courses_group-item').each(function () {
                            dataLayer.push($(this).data('layer'));
                        });

                        $(this).parent().submit();
                    });
                }
            }
        },
    });
})(jQuery);
