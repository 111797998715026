(function ($) {
    'use strict';

    $.truncate = new Object({
        init: function () {
            var opts = {
                tag: 'p',
                length: 200,
                suffix: '…',
                more: 'mehr',
                less: 'weniger',
                allButFirstParagraph: false,
                allowedPrevs: {
                    H2: 'H2',
                    H3: 'H3',
                    H4: 'H4',
                    H5: 'H5',
                },
            };

            $('[data-truncate]').each(function () {
                var _options = $(this).data('truncate'),
                    options = $.extend(opts, _options);

                var _elem = $(this).find(options.tag).first();

                if (_elem.length === 0) {
                    return void 0;
                }

                if (
                    _elem.prev().length > 0 &&
                    typeof options.allowedPrevs[_elem.prev().prop('tagName')] === 'undefined'
                ) {
                    return void 0;
                }

                if (options.allButFirstParagraph === true) {
                    _elem.addClass('do-not-hide').prev().addClass('do-not-hide');

                    var _prepend = $(this).find('.do-not-hide');
                    var _orig = $(this).find(' > *:not(.do-not-hide)');
                } else {
                    var _orig = _elem.html();
                }

                if (_orig.length < options.length) {
                    return void 0;
                }

                if (options.allButFirstParagraph === true) {
                    $(this).html('');
                    $(this).prepend(_prepend);
                    $(this)
                        .find('p')
                        .append(
                            $('<button />')
                                .text(options.more)
                                .attr('data-less', options.less)
                                .attr('data-more', options.more)
                                .addClass('full-toggle'),
                        );
                    $(this).append(
                        $('<div />')
                            .addClass('hide-me d-none')
                            .prepend(_orig)
                            .append(
                                $('<button />')
                                    .text(options.less)
                                    .attr('data-less', options.less)
                                    .attr('data-more', options.more)
                                    .addClass('full-toggle'),
                            ),
                    );
                } else {
                    _elem
                        .text('')
                        .append($('<span />').html(_orig.substr(0, options.length) + ' ' + options.suffix))
                        .append($('<span />').addClass('d-none').html(_orig))
                        .append(' ')
                        .append(
                            $('<button />')
                                .text(options.more)
                                .attr('data-less', options.less)
                                .attr('data-more', options.more)
                                .addClass('toggle'),
                        );
                }
            });

            $(document).on('click', '[data-truncate] .full-toggle', function (e) {
                e.preventDefault();

                if ($(this).parent().hasClass('do-not-hide')) {
                    $(this).parent().parent().find('.hide-me').removeClass('d-none');
                    $(this).addClass('d-none');
                } else {
                    $(this).parent().parent().find('.hide-me').addClass('d-none');
                    $(this).parent().parent().find('.full-toggle.d-none').removeClass('d-none');
                    var _scrollTo = $(this).parent().parent();

                    $('html, body')
                        .stop()
                        .animate(
                            {
                                scrollTop: _scrollTo.offset().top - ($('#header').height() + 50),
                            },
                            500,
                            'swing',
                            function () {},
                        );
                }
            });

            $(document).on('click', '[data-truncate] .toggle', function (e) {
                e.preventDefault();

                if (!$(this).hasClass('show')) {
                    $(this).parent().find('span:first-child').addClass('d-none');
                    $(this).parent().find('span + span').removeClass('d-none');

                    $(this).addClass('show');
                    $(this).text($(this).attr('data-less'));
                } else {
                    $(this).parent().find('span:first-child').removeClass('d-none');
                    $(this).parent().find('span + span').addClass('d-none');

                    $(this).removeClass('show');
                    $(this).text($(this).attr('data-more'));
                }
            });
        },
    });
})(jQuery);
