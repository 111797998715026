(function ($) {
    'use strict';

    $.cart = new Object({
        cart: {},

        init: function () {
            this.setCart();
            this.checkCart();
            this.clickHandler();
            this.checkBoxHandler();
            this.addToCart();
            this.addBadgeToCartIcon();
            this.ajax();
            this.finalize();
            this.quickSelect();
        },

        /**
         * prevents form from submitting
         * to show first the modal box
         * and then submits the form
         * due to safari behaviour
         */
        finalize: function () {
            var _form = $('form#finalize'),
                _modal = $('#orderModal');

            // prevents the form from beeing submitted
            _form.on('submit', function (e) {
                e.preventDefault();
            });

            // submits the form after modal was shown
            _form.find('[type="submit"]').on('click', function (e) {
                e.preventDefault();

                _modal.on('shown.bs.modal', function () {
                    _form.unbind('submit').submit();
                });
            });
        },

        setCart: function () {
            var _cart = Cookies.get('cart');
            if (typeof _cart !== 'undefined') {
                this.cart = JSON.parse(_cart);
            }
        },

        addToCart: function () {
            var that = this;

            $(document).on('click', 'div.add-to-cart button', function (e) {
                var _location = $('[data-element]').first().data('element').location;
                that.saveLocation(_location);

                if ($('form#quickselect').length > 0) {
                    that.cart['count'] = parseInt(that.cart['count']) + 1;
                    Cookies.set('cart', JSON.stringify(that.cart));
                }
            });
        },

        clickHandler: function () {
            $(document).on('click', 'a[data-cart]', function (e) {});
        },

        checkBoxHandler: function () {
            var that = this;

            $(document).on('click', '.courses_group-item input[type="checkbox"]', function () {
                var parent = $(this).parentsUntil('.courses_group').slice(-1)[0],
                    badge = $(parent).find('.badge.tiny');

                badge.removeClass('invisible');
                that.checkRowCheckboxes($(parent), badge);
                that.checkGlobalCheckboxes();
            });
        },

        checkRowCheckboxes: function (parent, badge) {
            var _count = parent.find('input[type="checkbox"]:checked').length;

            if (_count > 0) {
                badge.find('span > span:first-child').text(_count);
                parent.addClass('active');
            } else {
                badge.addClass('invisible');
                parent.removeClass('active');
            }

            parent.find('input[type="checkbox"]').each(function () {
                if ($(this).is(':checked')) {
                    $($(this).parentsUntil('li').slice(-1)[0]).addClass('active');
                } else {
                    $($(this).parentsUntil('li').slice(-1)[0]).removeClass('active');
                }
            });
        },

        checkGlobalCheckboxes: function () {
            var _check = $('.courses_group-item input[type="checkbox"]:checked'),
                _count = $('.courses_group-item input[type="checkbox"]:checked').length,
                _type,
                _text = $('div.add-to-cart button').data('text'),
                _type = 'event',
                _location = $('input[data-element]').first().data('element').location;

            $.each(_check, function () {
                if ($($(this).parentsUntil('.courses').slice(-1)[0]).parent().hasClass('event')) {
                    _type = 'event';
                } else {
                    _type = 'course';
                }
            });

            $('div.add-to-cart button').text(_text[_type]);

            if (_count > 0) {
                $('div.add-to-cart button')
                    .removeClass('disabled')
                    .removeAttr('disabled')
                    .parent()
                    .removeClass('disabled');
                $('.book-now').removeClass('show').addClass('d-none');
            } else {
                $('div.add-to-cart button')
                    .addClass('disabled')
                    .attr('disabled', 'disabled')
                    .parent()
                    .addClass('disabled');
            }

            this.checkLocation(_location);
            this.mobileBookButton(_count);
        },

        checkLocation: function (_location) {
            if (this.cart['location'] && this.cart['location'] !== _location) {
                $('div.add-to-cart button').attr('disabled', 'disabled');
                $('div.add-to-cart .alert').removeClass('d-none').addClass('d-flex show');
            } else {
                $('div.add-to-cart button').removeAttr('disabled');
                $('div.add-to-cart .alert').addClass('d-none').removeClass('d-flex show');
            }
        },

        saveLocation: function (_location) {
            this.cart['location'] = _location;
            Cookies.set('cart', JSON.stringify(this.cart));
            return void 0;
        },

        checkCart: function () {
            if ($('.frame-type-list-courses_cart .cart').length > 0) {
                var _count = $('.courses_group-item-schedule li').length;

                if (_count > 0) {
                    this.cart['count'] = _count;
                    Cookies.set('cart', JSON.stringify(this.cart));
                } else {
                    Cookies.remove('cart');
                    this.cart = {};
                }
            }

            if ($('.remove-cart').length >= 1) {
                Cookies.remove('cart');
                this.cart = {};
            }

            return void 0;
        },

        addBadgeToCartIcon: function () {
            if (!this.cart) {
                return void 0;
            }

            if (this.cart['count'] && this.cart['count'] > 0) {
                $('[data-cart]').attr('data-cart', this.cart['count']);
            } else {
                $('[data-cart]').attr('data-cart', 0);
            }

            return void 0;
        },

        ajax: function () {
            setTimeout(function () {
                $.ajax({
                    url: '/?type=1547721019',
                }).done(function (data) {
                    if (data === 0) {
                        Cookies.remove('cart');
                        this.cart = {};
                        $('[data-cart]').attr('data-cart', 0);
                    }
                });
            }, 1000);
        },

        mobileBookButton: function (_count) {
            var _originalButton = $('.add-to-cart').eq(0),
                _topButton = $('.add-to-cart:not(.clone)').eq(0),
                _bottomButton = $('.add-to-cart:not(.clone)').eq(1),
                _clone = _originalButton.clone().addClass('clone fixed-mobile-button d-none').removeClass('mb-30'),
                _tolerance = parseInt($('header#header').height() - _topButton.height());

            if ($('.fixed-mobile-button.clone').length === 0) {
                _originalButton.parent().prepend(_clone);
            }

            if (_topButton.isInViewport(_tolerance) || _bottomButton.isInViewport(0)) {
                _clone.addClass('d-none');
            } else {
                if (_count > 0) {
                    _clone.removeClass('d-none');
                } else {
                    _clone.addClass('d-none');
                }
            }

            $(document).on('scroll', function () {
                if (_topButton.isInViewport(_tolerance) || _bottomButton.isInViewport(0)) {
                    _clone.addClass('d-none');
                } else {
                    if (_count > 0) {
                        _clone.removeClass('d-none');
                    } else {
                        _clone.addClass('d-none');
                    }
                }
            });
        },

        // need this for crappy safari
        saveSelectValuesToStorage: function () {
            $('form#quickselect select').each(function () {
                var _tmp = [];
                $(this)
                    .find('option')
                    .each(function (k, v) {
                        _tmp.push(v.outerHTML);
                    });

                localStorage.setItem($(this).data('id'), JSON.stringify(_tmp));
            });
        },

        restoreSelectValuesFromStorage: function (selectField) {
            if (localStorage.getItem(selectField.data('id'))) {
                var _restore = JSON.parse(localStorage.getItem(selectField.data('id')));
                selectField.html(_restore);
            }
        },

        quickSelect: function () {
            var that = this;
            $('.select-holder').removeClass('selected');

            // save form values in localstorage due to safari shizzle
            if ($('form#quickselect').length > 0) {
                this.saveSelectValuesToStorage();
            }

            $('form#quickselect').on('submit', function (e) {
                e.preventDefault();
            });

            $('form#quickselect button').on('click', function (e) {
                e.preventDefault();

                var _selects = $('form#quickselect select').length;
                var _selected = $('.quickselect .selected').length;

                if (_selects === _selected) {
                    $('form#quickselect').unbind('submit').submit();
                    $('div.add-to-cart button')
                        .removeClass('disabled')
                        .removeAttr('disabled')
                        .parent()
                        .removeClass('disabled');
                } else {
                    $('div.add-to-cart button')
                        .addClass('disabled')
                        .attr('disabled', 'disabled')
                        .parent()
                        .addClass('disabled');
                }
            });

            $('form#quickselect select').on('change', function () {
                var _select = $(this),
                    _target = _select.data('target'),
                    _targetSelectField = $('select[data-id="' + _target + '"]'),
                    _disabled = 0;

                $('div.timeSlots').addClass('d-none');

                if ($(this).find('option:selected').index() === 0) {
                    $(this).parent().removeClass('selected');
                } else {
                    $(this).parent().addClass('selected');
                }

                if ($(this).data('id') === 'location') {
                    if ($(this).find('option:selected').index() === 0) {
                        $('[data-id="form"], [data-id="schedule"]')
                            .attr('disabled', true)
                            .parent()
                            .addClass('disabled');

                        $('.location-list').addClass('d-none');
                        $('.quickselect select').removeClass('small');

                        var _disabled = 1;
                    } else {
                        $('.location-list').removeClass('d-none');
                        $('.location-list li').addClass('d-none');
                        $('#Location-' + $(this).val()).removeClass('d-none');
                        $('.quickselect select').addClass('small');
                    }

                    $('[data-id="form"]').val($('[data-id="form"] option:first-child').val());

                    $('[data-id="schedule"]')
                        .val($('[data-id="schedule"] option:first-child').val())
                        .attr('disabled', true)
                        .parent()
                        .addClass('disabled');
                }

                if (_targetSelectField.length === 1 && _disabled === 0) {
                    that.restoreSelectValuesFromStorage(_targetSelectField);

                    _targetSelectField.removeAttr('disabled').find('option[value]').attr('hidden', true);
                    _targetSelectField.parent().removeClass('disabled');
                    _targetSelectField
                        .find('option[data-' + _select.data('id') + '="' + _select.val() + '"]')
                        .removeAttr('hidden');
                    _targetSelectField.find('option[hidden]').remove();

                    if (_targetSelectField.find('option:selected').index() !== 0) {
                        _targetSelectField.val(_targetSelectField.find('option:selected').val()).trigger('change');
                    }

                    if (_target === 'schedule') {
                        if (_targetSelectField.find('option').length === 2) {
                            _targetSelectField.val($(_targetSelectField.find('option')[1]).val()).change();
                        }
                    }
                }

                if (that.checkQuickSelect($(this)) === true) {
                    if ($(this).find('option:selected').index() === 0) {
                        return;
                    }

                    $('div.timeSlots').removeClass('d-none');
                    $('div.timeSlots li').hide();
                    $('.timeslot-' + JSON.parse($(this).val()).schedule).show();

                    $('div.add-to-cart button')
                        .removeClass('disabled')
                        .removeAttr('disabled')
                        .parent()
                        .removeClass('disabled');

                    var _location = $('[data-element]:not([hidden])').first().data('element').location;
                    that.checkLocation(_location);
                }
            });
        },

        checkQuickSelect: function (selectField) {
            return selectField.attr('data-id') === 'schedule';
        },
    });
})(jQuery);
