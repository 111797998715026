$.dflipInit = {
    init: function () {
        var book = $('[data-dflip="dflip"]');

        if (book) {
            var _path = book.data('dflipPath') || '/typo3conf/ext/theme/Resources/Public/dflip';

            DFLIP.defaults.soundFile = _path + '/sound/turn2.mp3';
            DFLIP.defaults.pdfjsSrc = _path + '/js/libs/pdf.min.js';
            DFLIP.defaults.pdfjsCompatibilitySrc = _path + '/js/libs/compatibility.js';
            DFLIP.defaults.pdfjsWorkerSrc = _path + '/js/libs/pdf.worker.min.js';
            DFLIP.defaults.threejsSrc = _path + '/js/libs/three.min.js';
            DFLIP.defaults.mockupjsSrc = _path + '/js/libs/mockup.min.js';
        }

        const flipbookPDFContainer = document.querySelectorAll('.flipbookPDFContainer');
        flipbookPDFContainer.forEach((container) => {
            const _pdf = container.dataset.source;
            $(container).flipBook(_pdf, {
                soundEnable: false,
                hideControls: 'thumbnail,outline,sound,share,download,more',
                moreControls: '',
            });
        });

        $('body').on('click', 'button[data-dflip="dflip"]', function (e) {
            var book = $(e.target).closest('[data-dflip="dflip"]');

            if (!window.dfLightBox) {
                window.dfLightBox = new DFLightBox(() => {
                    if (window.location.hash.indexOf('#dflip-') == 0) window.location.hash = '#_';
                    window.dfActiveLightBoxBook = null;
                });
            }

            window.dfLightBox.duration = 500;

            if (window.dfActiveLightBoxBook && window.dfActiveLightBoxBook.dispose) {
            } else {
                window.dfLightBox.show(() => {
                    var options = DFLIP.getOptions(book);
                    options.transparent = false;
                    options.soundEnable = false;
                    options.isLightBox = true;
                    options.soundEnable = false;
                    options.hideControls = 'thumbnail,outline,sound,share,download,more';
                    options.moreControls = '';

                    window.dfActiveLightBoxBook = $(window.dfLightBox.container).flipBook(
                        $(this).data('source'),
                        options,
                    );
                });
            }

            e.preventDefault();
        });
    },
};
