(function ($) {
    'use strict';

    $.history = new Object({
        init: function () {
            $(document).on('click', 'a.btn-back', function (e) {
                e.preventDefault();
                history.back();
            });
        },
    });
})(jQuery);
