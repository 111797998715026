(function ($) {
    'use strict';

    /**
     * add slider via data-attribute. possibility to set responsive slides via settings
     * e.g.: data-slider='{"dots":true, "autoplay": false, "arrows": false, "speed": 2000, "autoplaySpeed": 5000, "cssEase": "ease-in-out", "slidesToShow": 3, "slidesToScroll": 3, "breakpoints": { "sm" : 1, "md" : 2, "lg" : 3 }}'
     *
     *
     * @type {Object}
     */
    $.slickslider = new Object({
        init: function () {
            this.contentSlider();
            this.scrollTrigger();
        },

        contentSlider: function () {
            $('[data-slider]').each(function () {
                var _options = $(this).data('slider');
                if (typeof _options.breakpoints !== 'undefined') {
                    var _responsive = [];
                    $.each(_options.breakpoints, function (k, v) {
                        var _obj = {
                            breakpoint: parseInt(window.breakpoints[k]),
                            settings: {
                                slidesToShow: v,
                                slidesToScroll: v,
                            },
                        };

                        _responsive.push(_obj);
                    });

                    _options.responsive = _responsive;
                }

                if (typeof _options.callback !== 'undefined') {
                    $(this).on('beforeChange', function () {
                        $.sticky.stickyHeader();
                    });
                }

                $(this).slick(_options);
            });
        },

        scrollTrigger: function () {
            const slider = document.querySelectorAll('[data-slider]');
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        let _slider = $(entry.target);
                        if (_slider.data('slider').autoplay === false && !_slider.hasClass('slided')) {
                            _slider.slick('next');
                            _slider.addClass('slided');
                        }
                    }
                });
            });

            slider.forEach((item) => {
                observer.observe(item);
            });
        },
    });
})(jQuery);
