(function ($) {
    'use strict';

    $.sticky = new Object({
        init: function () {
            this.getElement();
        },

        stickyHeader: function () {
            var _root = document.documentElement;
            var _stickyHeight = $('.navigation-top').height();

            if ($('.navigation-top + .slider').length > 0) {
                _stickyHeight = _stickyHeight + $('.navigation-top + .slider').height();
            }

            _root.style.setProperty('--sticky-height', '-' + parseInt(_stickyHeight) + 'px');
        },

        getElement: function () {
            $('.sticky[data-sticky]').each(function () {
                var _elem = $(this).data('sticky'),
                    _elem = $(_elem);

                var _height = _elem.height();

                // reduce the height, if threshold element exists
                // incl. an additional space if 5px (logo marker)
                if ($(this).data('stickyThreshold')) {
                    var _thresholdElem = $(this).data('stickyThreshold');

                    if ($(_thresholdElem).length > 0) {
                        _height = _height - ($(_thresholdElem).height() - 5);
                    }
                }

                $(this).css('top', _height);
            });
        },
    });
})(jQuery);
