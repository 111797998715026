(function ($) {
    'use strict';

    $.footerMap = new Object({
        init: function () {
            $('#footer ul li a[data-location]')
                .on('mouseover', function () {
                    $('#footer ol li a[data-location="' + $(this).data('location') + '"]').addClass('hover');
                })
                .on('mouseout', function () {
                    $('#footer ol li a[data-location="' + $(this).data('location') + '"]').removeClass('hover');
                });
        },
    });
})(jQuery);
